<template>
  <center-content-template>
    <v-card
        flat
        class="py-15 elevation-0"
    >

      <v-row>
        <v-col class="text-center"
        >
          <img src="@/assets/logo.svg" width="50%" alt="" class="mb-10">
          <h2 class="font-weight-medium">{{ $t('account.password.reset.title') }}</h2>
          <p class="font-weight-light pb-0" v-if="validToken && !completed">
            {{ $t('account.password.reset.subtitle') }}
          </p>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-form v-if="validToken && !completed"
              ref="registrationForm"
              v-model="valid"
              lazy-validation
              @submit="resetPassword"
      >
        <v-row>
          <v-col class="px-15"
          >
            <v-alert
                v-if="error"
                outlined
                type="error"
                prominent
            >
              {{ error }}
            </v-alert>

            <v-text-field
                :label="$t('users.fields.password')"
                type="password"
                v-model="password"
                required
                :rules="passwordRules"
            ></v-text-field>

            <v-text-field
                :label="$t('users.fields.verify_password')"
                type="password"
                v-model="verify_password"
                required
                :rules="confirmPasswordRules"
            ></v-text-field>

          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center"
          >
            <v-btn type="submit" :loading="isLoading" large color="primary">
              {{ $t('auth.login-form.login-button') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-alert v-else-if="!validToken"
               color="error"
               border="bottom"
               class="text-center text-uppercase font-weight-bold white--text"
      >Token non valido
      </v-alert>

      <div v-else class="text-center my-5">
        <v-alert color="success"
                 border="bottom"
                 dense
                 text
                 class="text-center text-uppercase font-weight-bold white--text"
        >{{ $t('account.password.reset.complete.title') }}
        </v-alert>
        <p class="text-uppercase"><a class="font-weight-bold"
                                     href="/login">{{ $t('account.password.reset.complete.link') }}</a></p>
      </div>

    </v-card>
  </center-content-template>
</template>

<script>

import CenterContentTemplate from "@/views/Layout/_partials/CenterContentTemplate";
import {mapGetters} from "vuex";

export default {
  name: "ResetPassword",

  components: {
    CenterContentTemplate
  },

  data() {
    return {
      valid: true,
      completed: false,
      password: '',
      verify_password: '',
      passwordRules: [
        (value) => !!value || 'Please type password.',
        (value) => (value && value.length >= 6) || 'minimum 6 characters',
      ],
      confirmPasswordRules: [
        (value) => !!value || 'type confirm password',
        (value) =>
            value === this.password || 'The password confirmation does not match.',
      ],
      error: '',
    }
  },

  mounted() {
    this.$store.dispatch('account/verifyResetPasswordToken', this.$route.params.token)
  },

  computed: {
    ...mapGetters({
      isLoading: 'account/loadingState',
      validToken: 'account/getIsValidResetPasswordToken'
    }),
  },

  methods: {
    async resetPassword(event) {
      event.preventDefault()

      const valid = await this.$refs.registrationForm.validate()

      // VALIDATION
      if (!valid)
        return false;

      if (valid === true) {
        this.$store.dispatch('account/resetPassword', {password: this.password, token: this.$route.params.token})
            .then(() => {
              this.completed = true
            })
            .catch(() => {
              this.error = this.$t('account.password.reset.fail')
            })
      }
    }
  }
}
</script>

<style scoped>

</style>