<template>
  <center-content-template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit="recoveryPassword"
    >

      <v-card
          flat
          class="py-15 elevation-0"
      >

        <v-row>
          <v-col class="text-center"
          >
            <img src="@/assets/logo.svg" width="50%" alt="" class="mb-10">
            <h2 class="font-weight-medium">{{
                (!complete) ? $t('account.password.forgot.title') : $t('account.password.forgot.complete.title')
              }}</h2>
            <p class="font-weight-light pb-0">{{
                (!complete) ? $t('account.password.forgot.hint') : $t('account.password.forgot.complete.hint', {email: email})
              }}</p>
          </v-col>
        </v-row>

        <v-alert
            v-if="error"
            outlined
            type="error"
            prominent
        >
          {{ error }}
        </v-alert>

        <template v-if="!complete">
          <v-row>
            <v-col class="px-15"
            >

              <v-text-field
                  :label="$t('auth.login-form.email')"
                  name="login"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="email"
                  required
                  :rules="validEmailRules"
                  @keyup="error = ''"
                  @focus="email = ''"
              ></v-text-field>

            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center"
            >
              <v-btn type="submit" :loading="isWorking" large color="primary" @click="recoveryPassword">
                {{ $t('account.password.forgot.btn_confirm') }}
              </v-btn>
              <divider :margin-top="5"></divider>
              <small>
                {{ $t('account.password.forgot.confirm_hint') }}<br>
                <router-link to="/">{{ $t('commons.actions.cancel') }}</router-link>
              </small>
            </v-col>
          </v-row>
        </template>
      </v-card>

    </v-form>
  </center-content-template>
</template>

<script>

import CenterContentTemplate from "@/views/Layout/_partials/CenterContentTemplate";
import {validationRules} from "@/mixins/validationRules";
import {mapGetters} from "vuex";

export default {
  name: "ForgotPassword",

  mixins: [validationRules],

  components: {
    CenterContentTemplate
  },

  data() {
    return {
      valid: true,
      email: '',
      complete: false,
      error: ''
    }
  },

  computed: {
    ...mapGetters({
      isWorking: 'account/isWorking'
    })
  },

  methods: {
    async recoveryPassword(event) {

      event.preventDefault()

      const valid = await this.$refs.form.validate()

      // VALIDATION
      if (!valid)
        return false;

      if (valid === true) {
        await this.$store.dispatch('account/sendRecoveryPasswordEmail', this.email)
            .then(() => {
              console.log('ok')
              this.complete = true
              this.error = ''
            })
            .catch(() => {
              console.log('ko')
              this.error = this.$t('account.password.forgot.unknown_email')
            })
      }
    }
  }
}
</script>

<style scoped>

</style>